import React, { Fragment, Suspense, lazy, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Route } from 'react-router-dom';
import { compose } from 'recompose';
import { useSelector, useDispatch } from 'react-redux';
import { ApolloProvider } from '@apollo/react-hooks';
import withRoot from '../withRoot';
import { Header } from './common';
import { setAuthData, setApolloClient } from '../redux/actions';
import { withFirebase } from '../firebase';
import Constants from '../constants';
import CustomSnackbar from './common/CustomSnackbar';
import Breadcrumb from './common/Breadcrumb';
import getApolloClient from '../graphql/getApolloClient';

const App = props => {
   const {
      firebase,
      location: { pathname }
   } = props;

   const authData = useSelector(state => state.authData);
   const dispatch = useDispatch();

   useEffect(() => {
      firebase.auth.onAuthStateChanged(async user => {
         if (user) {
            const idToken = await user.getIdToken(/* forceRefresh */ true);
            const snapshot = await firebase.user(user.uid).once('value');
            let userData = {};
            const dbUser = snapshot.val();
            if (!dbUser) {
               userData.role = Constants.role.GUEST;
            } else {
               userData = {
                  ...user,
                  ...dbUser
               };
            }

            dispatch(
               setAuthData({
                  name: userData.displayName || userData.email,
                  email: userData.email,
                  role: userData.role,
                  token: idToken
               })
            );
         } else {
            dispatch(setAuthData({ role: Constants.role.GUEST, token: '' }));
         }
      });

      return function cleanup() {
         //         listener = null;
      };
      // eslint-disable-next-line
   }, []);

   console.log('authData', authData)

   if (authData) {
      // Can only use default import
      const Folders = lazy(() => import('./folders/Folders'));

      // replace '(' and ')' with '\(' and '\)' as '(' and ')' have special meaning in React Route path matching
      const encodedUrl = pathname.replace(/\(/g, '\\(').replace(/\)/g, '\\)');

      const apClient = getApolloClient(authData.token);
      dispatch(setApolloClient(apClient));

      return (
         <ApolloProvider client={apClient}>
            <Fragment>
               <Header />
               <Breadcrumb path={pathname} />
               <Suspense fallback={<div>Loading..</div>}>
                  <Route path={encodedUrl} component={Folders} />
               </Suspense>
               <CustomSnackbar />
            </Fragment>
         </ApolloProvider>
      );
   }
   return null;
};

App.propTypes = {
   location: PropTypes.object.isRequired,
   firebase: PropTypes.object.isRequired
};

const withCompose = compose(
   withRoot,
   withFirebase,
   withRouter
);

const AppMemo = React.memo(App);

AppMemo.whyDidYouRender = true;
export default withCompose(AppMemo);
