import React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import CssBaseline from '@material-ui/core/CssBaseline';
import Constants from './constants';
import { useSelector } from 'react-redux';
import { darkTheme, lightTheme } from './themes';

function withRoot(Component) {
   function WithRoot(props) {
      // MuiThemeProvider makes the theme available down the React tree
      // thanks to React context.
      const theme = useSelector(state => state.theme);

      return (
         <MuiThemeProvider
            theme={theme === Constants.THEME_TYPE.DARK ? darkTheme : lightTheme}
         >
            <ThemeProvider
               theme={
                  theme === Constants.THEME_TYPE.DARK ? darkTheme : lightTheme
               }
            >
               {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
               <CssBaseline />
               <Component {...props} />
            </ThemeProvider>
         </MuiThemeProvider>
      );
   }

   return WithRoot;
}

export default withRoot;
