import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
   Button,
   FormControl,
   Input,
   InputLabel,
   Typography,
   Dialog,
   DialogContentText,
   DialogContent,
   DialogActions
} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { compose } from 'recompose';
import { useSelector, useDispatch } from 'react-redux';
import { withFirebase } from '../../firebase';
import {
   setPasswordResetVisible,
   setSignInWithEmailVisible
} from '../../redux/actions';
import useForm from '../../hooks/useForm';

const styles = theme => ({
   paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
         3
      )}px`
   },
   link: {
      cursor: 'pointer',
      display: 'inline-block',
      marginLeft: theme.spacing(1)
   },
   inline: {
      display: 'inline-block'
   },
   errorMessage: {
      marginTop: theme.spacing(3),
      color: theme.palette.error.main
   }
});

const formDefaults = {
   email: '',
   password: ''
};

const SignInWithEmailDialog = props => {
   const signInWithEmailVisible = useSelector(
      state => state.dialogVisibility.signInWithEmailVisible
   );
   const dispatch = useDispatch();

   const [values, handleFormChange, resetForm] = useForm(formDefaults);
   const [error, setError] = useState('');

   const { classes, firebase } = props;

   const handleClose = () => {
      dispatch(setSignInWithEmailVisible(false));
   };

   return (
      <Dialog
         fullWidth
         maxWidth='xs'
         open={signInWithEmailVisible}
         onClose={handleClose}
         onEnter={() => {
            resetForm(formDefaults);
            setError('');
         }}
      >
         <form
            onSubmit={event => {
               event.preventDefault();
               firebase
                  .signWithCredentials(values.email, values.password)
                  .then(() => {
                     resetForm(formDefaults);
                     setError(null);

                     handleClose();
                  })
                  .catch(err => {
                     setError(err.message);
                  });
            }}
         >
            <DialogContent className={classes.paper}>
               <DialogContentText color='primary'>
                  Sign in with email
               </DialogContentText>
               <FormControl margin='normal' required fullWidth>
                  <InputLabel htmlFor='email'>Email</InputLabel>
                  <Input
                     id='email'
                     required
                     autoComplete='email'
                     autoFocus
                     name='email'
                     value={values.email}
                     onChange={handleFormChange}
                  />
               </FormControl>
               <FormControl margin='normal' required fullWidth>
                  <InputLabel htmlFor='password'>Password</InputLabel>
                  <Input
                     id='password'
                     required
                     type='password'
                     autoComplete='current-password'
                     name='password'
                     value={values.password}
                     onChange={handleFormChange}
                  />
               </FormControl>

               <div style={{ marginTop: 10 }}>
                  <Typography variant='body2' className={classes.inline}>
                     Forgot your password?
                  </Typography>
                  <Typography
                     className={classes.link}
                     variant='body2'
                     color='primary'
                     onClick={() => {
                        dispatch(setPasswordResetVisible(true));
                     }}
                  >
                     Reset Password
                  </Typography>
               </div>

               {error && (
                  <Typography
                     className={classes.errorMessage}
                     color='secondary'
                     variant='body1'
                     gutterBottom
                  >
                     {error}
                  </Typography>
               )}
            </DialogContent>
            <DialogActions>
               <Button onClick={handleClose} size='small'>
                  Cancel
               </Button>
               <Button
                  variant='contained'
                  type='submit'
                  color='primary'
                  size='small'
               >
                  Log in
               </Button>
            </DialogActions>
         </form>
      </Dialog>
   );
};

SignInWithEmailDialog.propTypes = {
   classes: PropTypes.object.isRequired,
   firebase: PropTypes.object.isRequired
};

const withCompose = compose(
   withStyles(styles),
   withFirebase
)(SignInWithEmailDialog);

export default withCompose;
