import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Constants from '../../constants';

const useStyles = makeStyles(theme => ({
   dropzone: props => ({
      [theme.breakpoints.down('sm')]: {
         height: 100,
         width: 100
      },
      [theme.breakpoints.up('md')]: {
         height: 200,
         width: 200
      },
      alignSelf: 'center',
      backgroundColor: props.hightlight
         ? theme.palette.primary.main
         : theme.palette.secondary.light,
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column'
   }),

   uploadMessage: {
      [theme.breakpoints.down('sm')]: {
         fontSize: '0.5rem'
      },
      [theme.breakpoints.up('md')]: {
         fontSize: '1rem'
      },
      alignSelf: 'center'
   },

   icon: {
      alignSelf: 'center',
      opacity: 0.5,
      height: 64,
      width: 64
   },

   fileInput: {
      display: 'none'
   }
}));

const Dropzone = ({ disabled, onFilesAdded }) => {
   const [fileInputRef] = useState(React.createRef());
   const [hightlight, setHightlight] = useState(false);

   const classes = useStyles({ hightlight });

   const openFileDialog = () => {
      if (disabled) return;

      fileInputRef.current.click();
   };

   const handleOnFilesAdded = ({ target: { files } }) => {
      if (disabled) return;
      if (onFilesAdded) {
         onFilesAdded(fileListToArray(files));
      }
   };

   const onDragOver = event => {
      event.preventDefault();
      if (disabled) return;
      setHightlight(true);
   };

   const onDragLeave = () => {
      setHightlight(false);
   };

   const onDrop = event => {
      event.preventDefault();
      if (disabled) return;
      if (onFilesAdded) {
         onFilesAdded(fileListToArray(event.dataTransfer.files));
      }
      setHightlight(false);
   };

   const fileListToArray = list => {
      const array = [];
      for (let i = 0; i < list.length; i++) {
         array.push(list.item(i));
      }
      return array;
   };

   return (
      <div
         className={classes.dropzone}
         onDragOver={onDragOver}
         onDragLeave={onDragLeave}
         onDrop={onDrop}
         onClick={openFileDialog}
         style={{ cursor: disabled ? 'default' : 'pointer' }}
      >
         <input
            ref={fileInputRef}
            className={classes.fileInput}
            type='file'
            multiple
            accept={Constants.MEDIA_TYPES.join()}
            onChange={handleOnFilesAdded}
         />
         <CloudUploadIcon className={classes.icon} />
         <Typography className={classes.uploadMessage} variant='subtitle2'>
            Click or drag here
         </Typography>
         {/* <span>Click or drag here</span> */}
      </div>
   );
};

Dropzone.propTypes = {
   disabled: PropTypes.bool.isRequired,
   onFilesAdded: PropTypes.func.isRequired
};

export default Dropzone;
