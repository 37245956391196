import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
   DialogContent,
   DialogActions,
   DialogContentText,
   Button,
   FormControl,
   Input,
   InputLabel,
   Dialog,
   Typography
} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { compose } from 'recompose';
import { useSelector, useDispatch } from 'react-redux';
import { setSnackbar, setPasswordChangeVisible } from '../../redux/actions';
import useForm from '../../hooks/useForm';
import { withFirebase } from '../../firebase';

const styles = theme => ({
   paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
         3
      )}px`
   },
   errorMessage: {
      marginTop: theme.spacing(3),
      color: theme.palette.error.main
   }
});

const formDefaults = {
   passwordOne: '',
   passwordTwo: ''
};

const PasswordChangeDialog = props => {
   const passwordChangeVisible = useSelector(
      state => state.dialogVisibility.passwordChangeVisible
   );
   const dispatch = useDispatch();
   const [values, handleFormChange, resetForm] = useForm(formDefaults);
   const [error, setError] = useState('');

   const { classes, firebase } = props;

   const handleClose = () => {
      dispatch(setPasswordChangeVisible(false));
   };

   const onSubmit = () => {
      firebase
         .passwordUpdate(values.passwordOne)
         .then(() => {
            dispatch(setSnackbar({ message: 'Password successfully updated' }));
            resetForm(formDefaults);
            setError('');

            handleClose();
         })
         .catch(err => {
            console.log(err);
            setError(err.message);
         });
   };

   const isInvalid =
      values.passwordOne !== values.passwordTwo ||
      values.passwordOne === '' ||
      values.passwordTwo === '';

   return (
      <Dialog
         open={passwordChangeVisible}
         onClose={handleClose}
         fullWidth
         maxWidth='xs'
         onEnter={() => {
            resetForm(formDefaults);
            setError('');
         }}
      >
         <DialogContent>
            <form className={classes.paper}>
               <DialogContentText color='primary'>
                  Password Change
               </DialogContentText>
               <FormControl margin='normal' required fullWidth>
                  <InputLabel htmlFor='passwordOne'>Password</InputLabel>
                  <Input
                     id='passwordOne'
                     name='passwordOne'
                     type='password'
                     fullWidth
                     autoFocus
                     placeholder='minimum 6 characters'
                     autoComplete='current-password'
                     value={values.passwordOne}
                     onChange={handleFormChange}
                  />
               </FormControl>
               <FormControl margin='normal' required fullWidth>
                  <InputLabel htmlFor='passwordTwo'>
                     Confirm New Password
                  </InputLabel>
                  <Input
                     id='passwordTwo'
                     name='passwordTwo'
                     type='password'
                     placeholder='minimum 6 characters'
                     autoComplete='current-password'
                     value={values.passwordTwo}
                     onChange={handleFormChange}
                  />
               </FormControl>
               {error && (
                  <Typography
                     variant='subtitle2'
                     className={classes.errorMessage}
                  >
                     {error}
                  </Typography>
               )}
            </form>
         </DialogContent>
         <DialogActions>
            <Button onClick={handleClose} size='small'>
               Cancel
            </Button>
            <Button
               disabled={isInvalid}
               variant='contained'
               color='primary'
               onClick={onSubmit}
            >
               Update Password
            </Button>
         </DialogActions>
      </Dialog>
   );
};

PasswordChangeDialog.propTypes = {
   classes: PropTypes.object.isRequired,
   firebase: PropTypes.object.isRequired
};

const withCompose = compose(
   withStyles(styles),
   withFirebase
)(PasswordChangeDialog);

export default withCompose;
