import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
   Button,
   Input,
   InputLabel,
   Dialog,
   DialogContentText,
   DialogContent,
   DialogActions,
   FormControl,
   Typography
} from '@material-ui/core';
import { compose } from 'recompose';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { withFirebase } from '../../firebase';
import { setSnackbar, setPasswordResetVisible } from '../../redux/actions';
import useForm from '../../hooks/useForm';

const useStyles = makeStyles(theme => ({
   paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
         3
      )}px`
   },
   errorMessage: {
      marginTop: theme.spacing(3),
      color: theme.palette.error.main
   }
}));

const formDefaults = {
   email: ''
};

const PasswordResetDialog = props => {
   const passwordResetVisible = useSelector(
      state => state.dialogVisibility.passwordResetVisible
   );

   const dispatch = useDispatch();
   const classes = useStyles();

   const [values, handleFormChange, resetForm] = useForm(formDefaults);
   const [error, setError] = useState(null);

   const { firebase } = props;

   const handleClose = () => {
      dispatch(setPasswordResetVisible(false));
   };

   const onSubmit = event => {
      event.preventDefault();
      firebase
         .passwordReset(values.email)
         .then(() => {
            dispatch(
               setSnackbar({
                  message: 'Reset email sent'
               })
            );
            resetForm(formDefaults);
            setError(null);
            handleClose();
         })
         .catch(err => {
            console.log(err);
            setError(err.message);
         });
   };

   return (
      <Dialog
         fullWidth
         maxWidth='xs'
         onEnter={() => resetForm(formDefaults)}
         open={passwordResetVisible}
         onClose={handleClose}
      >
         <form onSubmit={onSubmit}>
            <DialogContent className={classes.paper}>
               <DialogContentText color='primary'>
                  Password Reset
               </DialogContentText>
               <FormControl margin='normal' required fullWidth>
                  <InputLabel htmlFor='email'>Email</InputLabel>
                  <Input
                     id='email'
                     name='email'
                     required
                     autoComplete='email'
                     fullWidth
                     autoFocus
                     value={values.email}
                     onChange={handleFormChange}
                  />
               </FormControl>
               {error && (
                  <Typography
                     className={classes.errorMessage}
                     color='secondary'
                     variant='body1'
                     gutterBottom
                  >
                     {error}
                  </Typography>
               )}
            </DialogContent>
            <DialogActions>
               <Button onClick={handleClose} size='small'>
                  Cancel
               </Button>
               <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  size='small'
               >
                  Reset
               </Button>
            </DialogActions>
         </form>
      </Dialog>
   );
};

PasswordResetDialog.propTypes = {
   firebase: PropTypes.object.isRequired
};

const withCompose = compose(withFirebase)(PasswordResetDialog);

export default withCompose;
