import React, { useState, useEffect, useRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import SuperTreeview from 'react-super-treeview';
import 'react-super-treeview/dist/style.css';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { compose } from 'recompose';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { FOLDERS_QUERY } from '../../graphql/queries';
import Constants from '../../constants';
import { getImagesCountString } from '../../utils';
import { setSnackbar } from '../../redux/actions';

const styles = theme => ({
   root: {},
   drawerTitle: {
      marginBottom: theme.spacing(2)
   }
});

const DrawerContent = props => {
   const apolloClient = useSelector(state => state.apolloClient);
   const dispatch = useDispatch();
   const theme = useTheme();

   const {
      classes,
      onClose,
      history,
      location: { pathname }
   } = props;

   const [treeData, setTreeData] = useState([]);

   const mountedRef = useRef(false);

   const SuperTreeviewStyled = styled.div.attrs()`
      .super-treeview-text {
         cursor: pointer;
      }

      .super-treeview-text:hover {
         color: ${theme.palette.primary.main};
         // text-decoration: underline;
      }

      .super-treeview-node-content input {
         display: none;
      }

      .super-treeview
         > div
         > .super-treeview-node
         > .super-treeview-node-content
         > .super-treeview-triangle-btn-down {
         border-top: 6px solid ${theme.palette.primary.main};
      }

      .super-treeview
         > div
         > .super-treeview-node
         > .super-treeview-node-content
         > .super-treeview-triangle-btn-right {
         border-left: 6px solid ${theme.palette.primary.main};
      }

      .super-treeview
         > div
         > .super-treeview-node
         > .super-treeview-children-container {
         border-left: 1px solid ${theme.palette.primary.main};
      }

      .super-treeview
         > div
         > .super-treeview-node
         > .super-treeview-node-content
         > .super-treeview-triangle-btn-down
         ~ label {
         color: ${theme.palette.primary.main};
      }

      .super-treeview
         > div
         > .super-treeview-node
         > .super-treeview-node-content
         label::before {
         content: 'home';
         font-size: 16px;
         display: inline-block;
         margin-right: 3px;
         font-family: 'Material Icons';
         vertical-align: inherit;
         line-height: 1;
      }

      .super-treeview-children-container
         > .super-treeview
         > div
         > .super-treeview-node
         > .super-treeview-node-content
         > .super-treeview-triangle-btn-right
         ~ label::before {
         content: '';
      }

      .super-treeview-children-container
         > .super-treeview
         > div
         > .super-treeview-node
         > .super-treeview-node-content
         > .super-treeview-triangle-btn-down
         ~ label::before {
         content: '';
      }

      .super-treeview-children-container
         > .super-treeview
         > div
         > .super-treeview-node
         > .super-treeview-node-content
         > .super-treeview-triangle-btn-none
         ~ label::before {
         content: 'image';
         font-size: 16px;
         display: inline-block;
         margin-right: 3px;
         font-family: 'Material Icons';
         vertical-align: inherit;
         line-height: 1;
      }

      .super-treeview
         > div
         > .super-treeview-node
         > .super-treeview-node-content
         > label {
      }
   `;

   useEffect(() => {
      mountedRef.current = true;
      return () => {
         mountedRef.current = false;
      };
   }, []);

   const prepareData = data => {
      let result = [];

      const subfolders = [];
      data.subfolders.forEach(item => {
         if (item) {
            subfolders.push({
               id: item.path,
               name: item.name,
               type: Constants.viewMode.ALBUM
            });
         }
      });
      result = [...result, ...subfolders];

      const images = [
         {
            id: `${data.path}?view=image`,
            name: getImagesCountString(data.images.length),
            type: Constants.viewMode.IMAGE,
            imagesCount: data.images.length
         }
      ];

      result = [...result, ...images];

      return result;
   };

   const expandCurrentRouteNode = rootNode => {
      return new Promise(async resolve => {
         const split = pathname.split('/');
         split.shift(); // removes empty route before first /
         if (!_.isEmpty(rootNode)) {
            await iterateNodes(split, rootNode[0].children);
         }
         resolve();
      });
   };

   useEffect(() => {
      if (apolloClient) {
         apolloClient
            .query({
               query: FOLDERS_QUERY,
               variables: { path: '/' }
            })
            .then(response => {
               const result = prepareData(response.data.folder);
               const addedHomeResult = [
                  {
                     id: '/',
                     type: Constants.viewMode.ALBUM,
                     name: 'Home',
                     isExpanded: true,
                     children: result
                  }
               ];
               expandCurrentRouteNode(addedHomeResult).then(() => {
                  setTreeData(addedHomeResult);
               });
            })
            .catch(err => {
               console.log(err);
            });
      }
      // eslint-disable-next-line
   }, []);

   const iterateNodes = async (routesArray, nodesArray) => {
      if (_.isEmpty(routesArray)) {
         return;
      }
      const targetNode = nodesArray.find(item => item.name === routesArray[0]);
      if (targetNode) {
         const response = await apolloClient.query({
            query: FOLDERS_QUERY,
            variables: { path: targetNode.id }
         });

         const result = prepareData(response.data.folder);
         targetNode.isExpanded = true;
         targetNode.children = result;

         routesArray.shift();
         await iterateNodes(routesArray, targetNode.children);
      }
   };

   const findNode = (tree, node) => {
      for (let i = 0; i < tree.length; i++) {
         const item = tree[i];
         if (
            item.id === node.id &&
            item.name === node.name &&
            item.type === node.type
         ) {
            return item;
         }
         if (item.children) {
            const found = findNode(item.children, node);
            if (found) {
               return found;
            }
         }
      }
      return false;
   };

   const expandNode = (tree, node) => {
      if (node.isExpanded === true) {
         // This will show the loading sign
         node.isChildrenLoading = true;
         apolloClient
            .query({
               query: FOLDERS_QUERY,
               variables: { path: node.id }
            })
            .then(response => {
               const result = prepareData(response.data.folder);
               node.isChildrenLoading = false;
               const updatedData = _.cloneDeep(tree);
               const foundNode = findNode(updatedData, node);

               if (foundNode) {
                  if (result.length > 0) {
                     foundNode.children = result;
                  }
                  foundNode.isExpanded = true;
                  setTreeData(updatedData);
               }
            })
            .catch(err => {
               console.log(err);
            });
      }
   };

   return (
      <>
         <Typography className={classes.drawerTitle} data-testid='drawer-title'>
            Album quick selection
         </Typography>

         <SuperTreeviewStyled>
            <SuperTreeview
               isDeletable={() => false}
               noChildrenAvailableMessage='Loading..'
               data={treeData}
               onUpdateCb={updatedData => {
                  setTreeData(updatedData);
               }}
               onCheckToggleCb={arrayOfNodes => {
                  const selectedNode = arrayOfNodes[0];

                  let url = '';
                  switch (selectedNode.type) {
                     case Constants.viewMode.ALBUM:
                        url = selectedNode.id ? selectedNode.id : '/';
                        break;
                     case Constants.viewMode.IMAGE:
                        if (selectedNode.imagesCount > 0) {
                           url = selectedNode.id;
                        } else {
                           dispatch(
                              setSnackbar({
                                 message: 'No images in this album'
                              })
                           );
                        }
                        break;
                     default:
                     // just an empty url to point to a root
                  }

                  if (url) {
                     onClose();
                     history.push(url.replace(/\\/g, '/'));
                  }
               }}
               isCheckable={() => true}
               onExpandToggleCb={node => {
                  expandNode(treeData, node);
               }}
               isExpandable={node => {
                  return node.type === Constants.viewMode.ALBUM;
               }}
            />
         </SuperTreeviewStyled>
      </>
   );
};

DrawerContent.propTypes = {
   classes: PropTypes.object.isRequired,
   onClose: PropTypes.func.isRequired,
   history: PropTypes.object.isRequired,
   location: PropTypes.object.isRequired
};

const DrawerContentCompose = compose(
   withStyles(styles),
   withRouter
)(DrawerContent);

export default DrawerContentCompose;
