import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import {
   DialogActions,
   DialogContentText,
   DialogContent,
   FormControl,
   Button,
   Input,
   InputLabel,
   Dialog
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { CREATE_ALBUM_MUTATION, FOLDERS_QUERY } from '../../graphql/queries';
import Constants from '../../constants';
import { setSnackbar, setCreateAlbumVisible } from '../../redux/actions';

const path = require('path');

const styles = theme => ({
   form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
      display: 'flex'
   },
   paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
         3
      )}px`
   }
});

const CreateAlbumDialog = props => {
   const createAlbumVisible = useSelector(
      state => state.dialogVisibility.createAlbumVisible
   );

   const dispatch = useDispatch();
   const [name, setName] = useState('');
   const [description, setDescription] = useState('');
   const [error, setError] = useState('');

   const { classes, location } = props;

   const handleClose = () => {
      dispatch(setCreateAlbumVisible(false));
   };

   const [createAlbum, { error: mutationError }] = useMutation(
      CREATE_ALBUM_MUTATION
   );

   if (mutationError) {
      console.log(`GraphQL Error: ${mutationError}`);
      setTimeout(() => {
         dispatch(
            setSnackbar({
               message: 'Failed to create album',
               type: Constants.SNACKBAR_MESSAGE_TYPE.ERROR
            })
         );
      }, 1);

      return null;
   }

   return (
      <Dialog
         open={createAlbumVisible}
         onClose={handleClose}
         fullWidth
         maxWidth='xs'
         onEnter={() => {
            setName('');
            setDescription('');
            setError('');
         }}
      >
         <form
            onSubmit={event => {
               event.preventDefault();

               const url = path.join(location.pathname, name);
               createAlbum({
                  variables: {
                     path: url,
                     title: description
                  },
                  refetchQueries: [
                     {
                        query: FOLDERS_QUERY,
                        variables: { path: location.pathname }
                     }
                  ]
               })
                  .then(() => {
                     dispatch(
                        setSnackbar({
                           message: 'Created album'
                        })
                     );
                  })
                  .catch(res => {
                     const errors = res.graphQLErrors.map(err => {
                        return err.message;
                     });
                     console.log(`Promise errors: ${errors}`);
                  });

               handleClose();
            }}
         >
            <DialogContent className={classes.paper}>
               <DialogContentText color='primary'>New Album</DialogContentText>
               <FormControl margin='normal' required fullWidth>
                  <InputLabel htmlFor='name'>Name</InputLabel>
                  <Input
                     type='text'
                     id='name'
                     required
                     autoFocus
                     value={name}
                     onChange={event => setName(event.target.value)}
                  />
               </FormControl>
               <FormControl margin='normal' fullWidth>
                  <InputLabel htmlFor='description'>Description</InputLabel>
                  <Input
                     multiline
                     rowsMax='2'
                     type='text'
                     value={description}
                     onChange={event => setDescription(event.target.value)}
                  />
               </FormControl>

               {error && <p>{error.message}</p>}
            </DialogContent>
            <DialogActions>
               <>
                  <Button onClick={handleClose} size='small'>
                     Cancel
                  </Button>
                  <Button
                     type='submit'
                     variant='contained'
                     size='small'
                     color='primary'
                  >
                     Create
                  </Button>
               </>
            </DialogActions>
         </form>
      </Dialog>
   );
};

CreateAlbumDialog.propTypes = {
   classes: PropTypes.object.isRequired,
   location: PropTypes.object.isRequired
};

const withCompose = compose(
   withStyles(styles),
   withRouter
)(CreateAlbumDialog);

export default withCompose;
