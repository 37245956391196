import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
   progressBar: {
      width: '100%',
      height: 8,
      backgroundColor: theme.palette.secondary.light,
      borderRadius: 5
   },

   progress: {
      backgroundColor: theme.palette.primary.main,
      height: '100%',
      margin: 0,
      borderRadius: 5
   }
});

const Progress = ({ classes, progress }) => {
   return (
      <div className={classes.progressBar}>
         <div className={classes.progress} style={{ width: `${progress}%` }} />
      </div>
   );
};

Progress.propTypes = {
   classes: PropTypes.object.isRequired,
   progress: PropTypes.number.isRequired
};

export default withStyles(styles)(Progress);
