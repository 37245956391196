import { combineReducers } from 'redux';
import {
   selectedAlbumMetadataReducer,
   authDataReducer,
   showLoadingSpinnerReducer,
   apolloClientReducer,
   themeReducer,
   snackbarReducer,
   dialogVisibilityReducer
} from './reducers';

export default combineReducers({
   selectedAlbumMetadata: selectedAlbumMetadataReducer,
   authData: authDataReducer,
   showLoadingSpinner: showLoadingSpinnerReducer,
   apolloClient: apolloClientReducer,
   theme: themeReducer,
   snackbar: snackbarReducer,
   dialogVisibility: dialogVisibilityReducer
});
