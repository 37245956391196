import gql from 'graphql-tag';

const METADATA_FRAGMENT = gql`
   fragment metadataFields on Metadata {
      path
      title
      image
      visible
   }
`;

export const FOLDERS_QUERY = gql`
   query($path: ID!) {
      folder(path: $path) {
         name
         parent
         path
         metadata {
            ...metadataFields
         }
         images {
            path
            uri
         }
         subfolders {
            name
            parent
            path
            metadata {
               ...metadataFields
            }
         }
      }
   }

   ${METADATA_FRAGMENT}
`;

export const METADATA_QUERY = gql`
   query($path: ID!) {
      metadata(path: $path) {
         ...metadataFields
      }
   }

   ${METADATA_FRAGMENT}
`;

export const UPDATE_METADATA_MUTATION = gql`
   mutation UpdateMetadata($path: ID!, $metadata: MetadataInput) {
      updateMetadata(path: $path, metadata: $metadata) {
         path
         title
      }
   }
`;

export const CREATE_ALBUM_MUTATION = gql`
   mutation CreateAlbum($path: ID!, $title: String) {
      createAlbum(path: $path, title: $title)
   }
`;

export const UPDATE_VISIBILITY_MUTATION = gql`
   mutation updateVisibility($path: ID!, $visible: Boolean!) {
      updateVisibility(path: $path, visible: $visible)
   }
`;

export const RESET_ALBUM_IMAGE = gql`
   mutation resetAlbumImage($path: ID!) {
      resetAlbumImage(path: $path)
   }
`;
