class Constants {
   static viewMode = {
      ALBUM: 'album',
      IMAGE: 'image'
   };

   static tileSize = {
      height: 200,
      width: 200
   };

   static authProvider = {
      GOOGLE: 'google',
      FACEBOOK: 'facebook',
      TWITTER: 'twitter',
      CREDENTIALS: 'credentials'
   };

   static role = {
      ADMIN: 'admin',
      FAMILY: 'family',
      GUEST: 'guest'
   };

   static SNACKBAR_MESSAGE_TYPE = {
      DEFAULT: 'default',
      INFO: 'info',
      ERROR: 'error'
   };

   static THEME_TYPE = {
      LIGHT: 'light',
      DARK: 'dark'
   };

   static MEDIA_TYPES = ['.jpg', '.jpeg', '.png'];
}

export default Constants;
