import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import Constants from '../constants';

// Initialize Firebase
const config = {
   apiKey: 'AIzaSyB-8FfbtBBBGmPuhpovmdRNmn6PwYOoIMU',
   authDomain: 'photogallery-46123.firebaseapp.com',
   databaseURL: 'https://photogallery-46123.firebaseio.com',
   projectId: 'photogallery-46123',
   storageBucket: 'photogallery-46123.appspot.com',
   messagingSenderId: '172000672306'
};

class Firebase {
   constructor() {
      app.initializeApp(config);
      this.auth = app.auth();
      this.db = app.database();
      this.googleAuthProvider = new app.auth.GoogleAuthProvider();
      this.facebookAuthProvider = new app.auth.FacebookAuthProvider();
      this.twitterAuthProvider = new app.auth.TwitterAuthProvider();
   }

   signWithProvider = provider => {
      switch (provider) {
         case Constants.authProvider.GOOGLE:
            return this.auth.signInWithPopup(this.googleAuthProvider);
         case Constants.authProvider.FACEBOOK:
            return this.auth.signInWithPopup(this.facebookAuthProvider);
         case Constants.authProvider.TWITTER:
            return this.auth.signInWithPopup(this.twitterAuthProvider);
         default:
            return null;
      }
   };

   // *** AUTH API ***

   createUserWithEmailAndPassword = (email, password) =>
      this.auth.createUserWithEmailAndPassword(email, password);

   signWithCredentials = (email, password) =>
      this.auth.signInWithEmailAndPassword(email, password);

   signOut = () => this.auth.signOut();

   passwordReset = email => this.auth.sendPasswordResetEmail(email);

   passwordUpdate = password => this.auth.currentUser.updatePassword(password);

   // *** USER API ***

   user = uid => this.db.ref(`users/${uid}`);

   users = () => this.db.ref('users');
}

export default Firebase;
