import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
   Button,
   Typography,
   Dialog,
   DialogContent,
   DialogContentText
} from '@material-ui/core';
import { Google, Facebook, Twitter } from 'mdi-material-ui';
import { compose } from 'recompose';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import withStyles from '@material-ui/core/styles/withStyles';
import { useSelector, useDispatch } from 'react-redux';
import { withFirebase } from '../../firebase';
import Constants from '../../constants';
import {
   setSignInWithVisible,
   setSignUpVisible,
   setSignInWithEmailVisible
} from '../../redux/actions';

const styles = theme => ({
   paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
   },
   avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main
   },
   link: {
      cursor: 'pointer',
      display: 'inline-block',
      marginLeft: theme.spacing(1)
   },
   inline: {
      display: 'inline-block'
   },
   errorMessage: {
      marginTop: theme.spacing(3),
      color: theme.palette.error.main
   },
   provider: {
      display: 'flex',
      // width: '100%',
      justifyContent: 'space-between'
   },
   icon: {
      margin: theme.spacing(1)
   },
   button: {
      color: theme.palette.grey[300],
      '&:hover': {
         color: theme.palette.primary.main
      }
   },
   notRegisteredMessage: {
      marginTop: theme.spacing(2),
      paddingTop: theme.spacing(2),
      borderTop: '1px solid grey',
      width: '100%',
      display: 'flex',
      justifyContent: 'center'
   }
});

const SignInDialog = props => {
   const signInWithVisible = useSelector(
      state => state.dialogVisibility.signInWithVisible
   );
   const dispatch = useDispatch();

   const [error, setError] = useState('');

   const { classes } = props;

   const handleClose = () => {
      dispatch(setSignInWithVisible(false));
   };

   return (
      <Dialog
         fullWidth
         maxWidth='xs'
         open={signInWithVisible}
         onClose={handleClose}
         onEnter={() => setError('')}
      >
         <DialogContent>
            <form className={classes.paper} noValidate>
               <DialogContentText color='primary'>
                  Log in with
               </DialogContentText>
               <div className={classes.provider}>
                  <Google className={classes.icon} color='action' />
                  <Button
                     fullWidth
                     className={classes.button}
                     onClick={() => {
                        props.firebase
                           .signWithProvider(Constants.authProvider.GOOGLE)
                           .then(socialAuthUser => {
                              if (socialAuthUser.additionalUserInfo.isNewUser) {
                                 props.firebase
                                    .user(socialAuthUser.user.uid)
                                    .set({
                                       email: socialAuthUser.user.email,
                                       role: Constants.role.GUEST
                                    });
                              }
                              handleClose();
                           })
                           .catch(err => {
                              setError(err.message);
                           });
                     }}
                  >
                     Google
                  </Button>
               </div>
               <div className={classes.provider}>
                  <Facebook className={classes.icon} color='action' />
                  <Button
                     fullWidth
                     className={classes.button}
                     onClick={() => {
                        props.firebase
                           .signWithProvider(Constants.authProvider.FACEBOOK)
                           .then(socialAuthUser => {
                              if (socialAuthUser.additionalUserInfo.isNewUser) {
                                 props.firebase
                                    .user(socialAuthUser.user.uid)
                                    .set({
                                       email: socialAuthUser.user.email,
                                       role: Constants.role.GUEST
                                    });
                              }
                              handleClose();
                           })
                           .catch(err => {
                              setError(err.message);
                           });
                     }}
                  >
                     Facebook
                  </Button>
               </div>
               <div className={classes.provider}>
                  <Twitter className={classes.icon} color='action' />
                  <Button
                     fullWidth
                     className={classes.button}
                     onClick={() => {
                        props.firebase
                           .signWithProvider(Constants.authProvider.TWITTER)
                           .then(socialAuthUser => {
                              if (socialAuthUser.additionalUserInfo.isNewUser) {
                                 props.firebase
                                    .user(socialAuthUser.user.uid)
                                    .set({
                                       email: socialAuthUser.user.email,
                                       name: socialAuthUser.user.displayName,
                                       role: Constants.role.GUEST
                                    });
                              }
                              handleClose();
                           })
                           .catch(err => {
                              setError(err.message);
                           });
                     }}
                  >
                     Twitter
                  </Button>
               </div>
               <div className={classes.provider}>
                  <LockOutlinedIcon className={classes.icon} color='action' />
                  <Button
                     fullWidth
                     className={classes.button}
                     onClick={() => {
                        dispatch(setSignInWithEmailVisible(true));
                        handleClose();
                     }}
                  >
                     Email
                  </Button>
               </div>

               <div className={classes.notRegisteredMessage}>
                  <Typography variant='body2' className={classes.inline}>
                     Not registered?
                  </Typography>
                  <Typography
                     className={classes.link}
                     variant='body2'
                     color='primary'
                     onClick={() => {
                        dispatch(setSignUpVisible(true));
                        handleClose();
                     }}
                  >
                     Sign Up
                  </Typography>
               </div>

               {error && (
                  <Typography
                     variant='subtitle2'
                     className={classes.errorMessage}
                  >
                     {error}
                  </Typography>
               )}
            </form>
         </DialogContent>
      </Dialog>
   );
};

SignInDialog.propTypes = {
   classes: PropTypes.object.isRequired,
   firebase: PropTypes.object.isRequired
};

const withCompose = compose(
   withStyles(styles),
   withFirebase
)(SignInDialog);

export default withCompose;
