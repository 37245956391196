export const getServerPort = () => {
   return process.env.NODE_ENV === 'development' ? 80 : 443;
};

export const getServerProtocol = () => {
   return process.env.NODE_ENV === 'development' ? 'http' : 'https';
};

export const isValidYear = string => {
   const pattern = /^(19|20)[0-9][0-9]$/;
   return pattern.test(string);
};

export const getImagesCountString = (count) => {
   switch (count) {
      case 0:
         return "No images";
      case 1:
         return "1 image";
      default:
         return `${count} images`;
   }
};