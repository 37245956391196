import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import DialogActions from '@material-ui/core/DialogActions';
import PropTypes from 'prop-types';
import {
   Button,
   FormControl,
   Input,
   InputLabel,
   Dialog,
   DialogContent,
   DialogContentText,
   Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { UPDATE_METADATA_MUTATION, FOLDERS_QUERY } from '../../graphql/queries';
import { setSnackbar, setAlbumDescriptionVisible } from '../../redux/actions';
import Constants from '../../constants';

const useStyles = makeStyles(theme => ({
   paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
   },
   errorMessage: {
      marginTop: theme.spacing(3),
      color: theme.palette.error.main
   }
}));

const AlbumDescriptionDialog = props => {
   const selectedAlbumMetadata = useSelector(
      state => state.selectedAlbumMetadata
   );
   const albumDescriptionVisible = useSelector(
      state => state.dialogVisibility.albumDescriptionVisible
   );
   const dispatch = useDispatch();
   const classes = useStyles();
   const { location } = props;

   const [title, setTitle] = useState(selectedAlbumMetadata.title || '');
   const [error, setError] = useState(null);

   //   const currentlySelectedPath = location.pathname;
   //   const selectedCardPath = selectedAlbumMetadata.path;
   const handleClose = () => {
      dispatch(setAlbumDescriptionVisible(false));
   };

   const [updateMetadataMutation, { error: graphqlError }] = useMutation(
      UPDATE_METADATA_MUTATION
   );

   if (graphqlError) {
      setError(graphqlError.message);
      console.log(`GraphQL Error: ${graphqlError}`);
      setTimeout(() => {
         dispatch(
            setSnackbar({
               message: 'Failed to set description',
               type: Constants.SNACKBAR_MESSAGE_TYPE.ERROR
            })
         );
      }, 1);

      return null;
   }

   const graphqlUpdate = titleValue => {
      return updateMetadataMutation({
         variables: {
            path: selectedAlbumMetadata.path,
            metadata: {
               path: selectedAlbumMetadata.path,
               title: titleValue
            }
         },
         optimisticResponse: {
            __typename: 'Mutation',
            updateMetadata: {
               __typename: 'Metadata',
               path: selectedAlbumMetadata.path,
               title: ''
            }
         },
         update: (cache, { data: { updateMetadata } }) => {
            //                  updateMetadata is a mutation result.
            //                  in our case this is: { title image}
            if (location.pathname === selectedAlbumMetadata.path) {
               return;
            }
            const { folder } = cache.readQuery({
               query: FOLDERS_QUERY,
               variables: {
                  path: location.pathname
               }
            });
            const subfolder = folder.subfolders.find(
               item => item.path === selectedAlbumMetadata.path
            );

            if (subfolder) {
               //               subfolder.metadata.title = updateMetadata.title;
               subfolder.metadata.title = title;
               cache.writeQuery({
                  query: FOLDERS_QUERY,
                  data: { folder }
               });
            }
         }
      });
   };

   return (
      <Dialog
         fullWidth
         maxWidth='xs'
         open={albumDescriptionVisible}
         onClose={handleClose}
         onEnter={() => {
            setTitle(selectedAlbumMetadata.title || '');
         }}
      >
         <form
            onSubmit={event => {
               event.preventDefault();

               graphqlUpdate(title)
                  .then(() => {
                     // TODO: fix this second call to graphql - temporarily placed as workaround
                     graphqlUpdate(title);
                     handleClose();
                     dispatch(
                        setSnackbar({
                           message: 'Updated Album Title'
                        })
                     );
                  })
                  .catch(res => {
                     const errors = res.graphQLErrors.map(err => {
                        return err.message;
                     });
                     console.log(`Promise errors: ${errors}`);
                     // TODO: extract correct user friendly error
                     setError('Failed to update album description');
                  });
            }}
         >
            <DialogContent>
               <FormControl className={classes.paper}>
                  <DialogContentText color='primary'>
                     Album Description
                  </DialogContentText>
                  <FormControl margin='normal' fullWidth required>
                     <InputLabel htmlFor='description'>Description</InputLabel>
                     <Input
                        id='description'
                        required
                        autoFocus
                        fullWidth
                        type='text'
                        value={title}
                        onChange={event => setTitle(event.target.value)}
                     />
                  </FormControl>

                  {error && (
                     <Typography
                        variant='subtitle2'
                        className={classes.errorMessage}
                     >
                        {error}
                     </Typography>
                  )}
               </FormControl>
            </DialogContent>
            <DialogActions>
               <>
                  <Button onClick={handleClose} size='small'>
                     Cancel
                  </Button>
                  <Button
                     type='submit'
                     variant='contained'
                     color='primary'
                     size='small'
                  >
                     Add
                  </Button>
               </>
            </DialogActions>
         </form>
      </Dialog>
   );
};

AlbumDescriptionDialog.propTypes = {
   location: PropTypes.object.isRequired
};

const withCompose = compose(withRouter)(AlbumDescriptionDialog);

export default withCompose;
