import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import HomeIcon from '@material-ui/icons/Home';
import { Link } from 'react-router-dom';
import { Breadcrumbs, Grow } from '@material-ui/core';
import { compose } from 'recompose';
import useEventListener from './useEventListener';

const styles = theme => ({
   root: {
      alignItems: 'center',
      display: 'flex',
      backgroundColor: theme.palette.secondary.main,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
   },
   homeIcon: {
      color: theme.palette.grey[300],
      marginRight: theme.spacing(1),
      height: 22,
      '&:hover': {
         color: theme.palette.primary.main
      }
   },
   button: {
      color: theme.palette.grey[300]
   },
   slash: {
      color: theme.palette.grey[300]
   },
   link: {
      display: 'flex',
      textDecoration: 'none',
      color: theme.palette.grey[300],
      '&:hover': {
         color: theme.palette.primary.main,
         textDecoration: 'underline'
      }
   }
});

const Breadcrumb = props => {
   const { classes, path } = props;

   const [scrolling, setScrolling] = useState(false);
   const [scrollTop, setScrollTop] = useState(0);

   const onScroll = e => {
      setScrollTop(e.target.documentElement.scrollTop);
      setScrolling(e.target.documentElement.scrollTop > scrollTop);
   };

   useEventListener('scroll', onScroll);

   //   useRendersCount('Breadcrumb');

   const splitPath = fullpath => {
      const pathArray = fullpath.split('/');

      return pathArray.map((item, index) => {
         let toLink = '';
         for (let i = 0; i <= index && item !== ''; i++) {
            toLink += `${pathArray[i]}/`;
         }

         return (
            item !== '' && (
               <Link
                  key={toLink}
                  className={classes.link}
                  to={toLink}
                  color='inherit'
               >
                  {item}
               </Link>
            )
         );
      });
   };

   return (
      <Grow in={!scrolling}>
         <div className={classes.root}>
            <Breadcrumbs maxItems={10} separator='›' arial-label='Breadcrumb'>
               <Link color='inherit' to='/' className={classes.link}>
                  <HomeIcon
                     className={classes.homeIcon}
                     data-testid='home-icon'
                  />
                  Home
               </Link>

               {splitPath(path)}
            </Breadcrumbs>
         </div>
      </Grow>
   );
};

Breadcrumb.propTypes = {
   classes: PropTypes.object.isRequired,
   path: PropTypes.string.isRequired
};

const withCompose = compose(withStyles(styles))(Breadcrumb);

export default React.memo(withCompose);
