import React, { Fragment, useState } from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {
   Grow,
   Popper,
   MenuItem,
   MenuList,
   Button,
   Drawer,
   AppBar,
   Toolbar,
   IconButton,
   Tooltip,
   Hidden,
   Paper,
   Typography
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import MoreIcon from '@material-ui/icons/MoreVert';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { withFirebase } from '../../firebase';
import {
   SignInWithDialog,
   SignUpDialog,
   PasswordResetDialog,
   PasswordChangeDialog,
   SignInWithEmailDialog
} from '../login';
import DrawerContent from './DrawerContent';
import { ImageUploadDialog, CreateAlbumDialog } from '.';

// import { Lightbulb, LightbulbOn } from 'mdi-material-ui';
import {
   setMenuVisible,
   setNavigationDrawerVisible,
   setCreateAlbumVisible,
   setAddNewFilesVisible,
   setPasswordChangeVisible,
   setSignInWithVisible
} from '../../redux/actions';
import Constants from '../../constants';
import useEventListener from './useEventListener';

const drawerWidth = 400;

const styles = theme => {
   // console.log('THEME', theme);

   return {
      root: {
         display: 'flex',
         height: 50,
         justifyContent: 'space-between'
      },
      login: {
         display: 'flex',
         alignItems: 'center',
         flexWrap: 'nowrap'
      },

      drawerToolbar: theme.mixins.toolbar,

      appBar: {
         zIndex: theme.zIndex.drawer + 1
      },
      toolBar: {
         display: 'flex',
         justifyContent: 'space-between',
         backgroundColor: theme.palette.secondary.dark
      },
      drawer: {
         width: drawerWidth,
         flexShrink: 0
      },
      drawerPaper: {
         width: drawerWidth,
         padding: 10,
         backgroundColor: theme.palette.secondary.dark
      },
      drawerContent: {
         flexGrow: 1,
         marginTop: theme.spacing(2),
         paddingTop: theme.spacing(3),
         paddingRight: theme.spacing(3),
         paddingBottom: theme.spacing(3),
         paddingLeft: theme.spacing(3),
         // TODO: replace 'white' with default color from theme
         backgroundColor: theme.palette.secondary.main
      },
      drawerButton: {
         marginLeft: -theme.spacing(2),
         '&:hover': {
            color: theme.palette.primary.main
         }
      },
      button: {
         marginLeft: theme.spacing(1),
         '&:hover': {
            color: theme.palette.primary.main
         }
         // marginRight: theme.spacing(1)
      },
      appIcon: {
         marginLeft: theme.spacing(3),
         marginRight: theme.spacing(1)
      },
      title: {
         fontSize: 20,
         '&::first-letter': {
            color: 'transparent',
            textShadow: `0 0 2px ${theme.palette.primary.main}`
         },
         display: 'inline-block',
         marginRight: theme.spacing(1),
         color: 'transparent',
         textShadow: '0 0 2px rgba(255, 255, 255, 0.5)'
      },
      menu: {
         zIndex: 1300,
         border: `1px solid ${theme.palette.primary.main}`,
         borderRadius: '2%'
      },
      email: {
         cursor: 'pointer',
         '&:hover': {
            color: theme.palette.primary.main
         }
      }
   };
};

const Header = props => {
   const { classes, firebase } = props;

   const authData = useSelector(state => state.authData);
   // const theme = useSelector(state => state.theme);
   const menuVisible = useSelector(state => state.dialogVisibility.menuVisible);
   const navigationDrawerVisible = useSelector(
      state => state.dialogVisibility.navigationDrawerVisible
   );

   const dispatch = useDispatch();
   const [anchorEl, setAnchorEl] = useState({});
   const [scrolling, setScrolling] = useState(false);
   const [scrollTop, setScrollTop] = useState(0);

   const onScroll = e => {
      setScrollTop(e.target.documentElement.scrollTop);
      setScrolling(e.target.documentElement.scrollTop > scrollTop);
   };

   useEventListener('scroll', onScroll);

   const closeMenu = event => {
      if (anchorEl.contains(event.target)) {
         return;
      }
      dispatch(setMenuVisible(false));
   };

   return (
      <div className={classes.root}>
         <Grow in={!scrolling}>
            <AppBar className={classes.appBar} color='secondary'>
               <Toolbar variant='dense' className={classes.toolBar}>
                  <IconButton
                     className={classes.drawerButton}
                     color='inherit'
                     aria-label='Drawer'
                     data-testid='drawer-icon'
                     onClick={() =>
                        dispatch(
                           setNavigationDrawerVisible(!navigationDrawerVisible)
                        )
                     }
                  >
                     {navigationDrawerVisible ? (
                        <ArrowLeftIcon />
                     ) : (
                        <MenuIcon />
                     )}
                  </IconButton>

                  <Hidden mdDown>
                     <span>
                        <Typography className={classes.title}>Photo</Typography>
                        <Typography className={classes.title}>
                           Gallery
                        </Typography>
                     </span>
                  </Hidden>

                  <div className={classes.login}>
                     {authData && authData.name ? (
                        <Fragment>
                           <Tooltip title='Change password'>
                              <Typography
                                 className={classes.email}
                                 color='inherit'
                                 onClick={() =>
                                    dispatch(setPasswordChangeVisible(true))
                                 }
                              >
                                 {authData.name}
                              </Typography>
                           </Tooltip>
                           <Button
                              size='small'
                              className={classes.button}
                              variant='outlined'
                              color='inherit'
                              onClick={() => {
                                 firebase.signOut();
                              }}
                           >
                              Log out
                           </Button>
                        </Fragment>
                     ) : (
                        <Fragment>
                           <Button
                              size='small'
                              className={classes.button}
                              variant='outlined'
                              color='inherit'
                              onClick={() => {
                                 Promise.resolve().then(() => {
                                    dispatch(setSignInWithVisible(true));
                                 });
                              }}
                           >
                              Login
                           </Button>
                        </Fragment>
                     )}

                     {/* <Tooltip title='Change theme'>
                        <IconButton
                           className={classes.button}
                           color='inherit'
                           aria-label='Menu'
                           onClick={event => {
                              setTheme(
                                 theme === Constants.THEME_TYPE.DARK
                                    ? Constants.THEME_TYPE.LIGHT
                                    : Constants.THEME_TYPE.DARK
                              );
                           }}
                        >
                           {theme === Constants.THEME_TYPE.DARK ? (
                              <Lightbulb />
                           ) : (
                              <LightbulbOn />
                           )}
                        </IconButton>
                     </Tooltip> */}

                     {authData &&
                        authData.name &&
                        authData.role !== Constants.role.GUEST && (
                           <IconButton
                              className={classes.button}
                              color='inherit'
                              aria-label='Menu'
                              aria-owns={
                                 menuVisible ? 'menu-list-grow' : undefined
                              }
                              aria-haspopup='true'
                              onClick={event => {
                                 setAnchorEl(event.currentTarget);
                                 dispatch(setMenuVisible(!menuVisible));
                              }}
                           >
                              <MoreIcon />
                           </IconButton>
                        )}
                  </div>
               </Toolbar>
            </AppBar>
         </Grow>

         <Drawer
            className={classes.drawer}
            open={navigationDrawerVisible}
            classes={{
               paper: classes.drawerPaper
            }}
            style={{ zIndex: 100 }}
            onClose={() => dispatch(setNavigationDrawerVisible(false))}
         >
            <div className={classes.drawerToolbar} />
            <div className={classes.drawerContent}>
               <DrawerContent
                  onClose={() => dispatch(setNavigationDrawerVisible(false))}
               />
            </div>
         </Drawer>

         <SignInWithDialog />
         <SignInWithEmailDialog />
         <SignUpDialog />
         <PasswordResetDialog />
         <PasswordChangeDialog />
         <ImageUploadDialog />
         <CreateAlbumDialog />

         <Popper
            className={classes.menu}
            open={menuVisible}
            anchorEl={anchorEl}
            transition
            disablePortal
         >
            {({ TransitionProps, placement }) => (
               <Grow
                  {...TransitionProps}
                  id='menu-list-grow'
                  style={{
                     transformOrigin:
                        placement === 'bottom' ? 'center top' : 'center bottom'
                  }}
               >
                  <Paper>
                     <ClickAwayListener onClickAway={closeMenu}>
                        <MenuList>
                           <MenuItem
                              onClick={event => {
                                 dispatch(setCreateAlbumVisible(true));
                                 closeMenu(event);
                              }}
                           >
                              Create New Album
                           </MenuItem>
                           <MenuItem
                              onClick={event => {
                                 dispatch(setAddNewFilesVisible(true));
                                 closeMenu(event);
                              }}
                           >
                              Add New Photos
                           </MenuItem>
                        </MenuList>
                     </ClickAwayListener>
                  </Paper>
               </Grow>
            )}
         </Popper>

         <div className={classes.toolbar} />
      </div>
   );
};

Header.propTypes = {
   classes: PropTypes.object.isRequired,
   firebase: PropTypes.object.isRequired
};

//   withDialogVisibility,

const withCompose = compose(
   withStyles(styles),
   withRouter,
   withFirebase
)(Header);

export default withCompose;
