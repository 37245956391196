import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import reduxStore from './redux/store';
import App from './components/App';
import Firebase, { FirebaseContext } from './firebase';
import LoadingSpinner from './components/common/LoadingSpinner';
import packageJson from '../package.json';

const utils = require('./utils');

if (process.env.NODE_ENV !== 'production') {
   console.warn('Activating whyDidYouRender!');

   const whyDidYouRender = require('@welldone-software/why-did-you-render/dist/no-classes-transpile/umd/whyDidYouRender.min.js');
   whyDidYouRender(React);
}

const serverProtocol = utils.getServerProtocol();
const serverPort = utils.getServerPort();

console.log(`Version: ${packageJson.version}`);
console.log(`Environment: ${process.env.NODE_ENV}`);
console.log(
   `Server: ${serverProtocol}://${window.location.hostname}:${serverPort}`
);

const firebase = new Firebase();
const Root = () => {
   return (
      <FirebaseContext.Provider value={firebase}>
         <Provider store={reduxStore}>
            <BrowserRouter>
               <App />
            </BrowserRouter>
            <LoadingSpinner />
         </Provider>
      </FirebaseContext.Provider>
   );
};

ReactDOM.render(<Root />, document.getElementById('root'));
