import React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const styles = () => ({
   container: {
      position: 'fixed',
      width: '100%',
      height: window.outerHeight,
      fontFamily: 'Roboto',
      fontSize: '12px',
      left: 0,
      top: 0
   }
});

const LoadingSpinner = ({ classes }) => {
   const showSpinner = useSelector(state => state.showLoadingSpinner);

   return (
      <LoadingOverlay
         active={showSpinner}
         spinner
         className={classes.container}
         text='Loading'
         styles={{
            spinner: base => ({
               ...base,
               width: '30px'
            }),
            wrapper: base => ({
               ...base,
               zIndex: `${showSpinner ? 2000 : -100}`
            })
         }}
      />
   );
};

LoadingSpinner.propTypes = {
   classes: PropTypes.object.isRequired
};

export default withStyles(styles)(LoadingSpinner);
