import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
   DialogContent,
   DialogContentText,
   DialogActions,
   Button,
   FormControl,
   Input,
   InputLabel,
   Dialog,
   Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { compose } from 'recompose';
import { withFirebase } from '../../firebase';
import Constants from '../../constants';
import { setSnackbar, setSignUpVisible } from '../../redux/actions';
import useForm from '../../hooks/useForm';

const useStyles = makeStyles(theme => ({
   paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
   },
   errorMessage: {
      marginTop: theme.spacing(3),
      color: theme.palette.error.main
   }
}));

const formDefaults = {
   email: '',
   passwordOne: '',
   passwordTwo: ''
};

const SignUpDialog = ({ firebase }) => {
   const classes = useStyles();

   const signUpVisible = useSelector(
      state => state.dialogVisibility.signUpVisible
   );
   const dispatch = useDispatch();

   const [values, handleFormChange, resetForm] = useForm(formDefaults);
   const [error, setError] = useState('');

   const handleClose = () => {
      dispatch(setSignUpVisible(false));
   };

   const onSubmit = () => {
      firebase
         .createUserWithEmailAndPassword(values.email, values.passwordOne)
         .then(authUser => {
            return firebase.user(authUser.user.uid).set({
               email: values.email,
               role: Constants.role.GUEST
            });
         })
         .then(() => {
            dispatch(
               setSnackbar({
                  message: 'Signed in'
               })
            );

            resetForm(formDefaults);
            setError('');
            handleClose();
         })
         .catch(err => {
            console.log(err);
            setError(err.message);
         });
   };

   const isInvalid =
      values.passwordOne !== values.passwordTwo ||
      values.email === '' ||
      values.passwordOne === '' ||
      values.passwordTwo === '';

   return (
      <Dialog
         fullWidth
         maxWidth='xs'
         open={signUpVisible}
         onClose={handleClose}
         onEnter={() => {
            resetForm(formDefaults);
            setError('');
         }}
      >
         <form>
            <DialogContent className={classes.paper}>
               <DialogContentText color='primary'>
                  Create new Account
               </DialogContentText>
               <FormControl margin='normal' required fullWidth>
                  <InputLabel htmlFor='email'>Email</InputLabel>
                  <Input
                     id='email'
                     name='email'
                     fullWidth
                     autoComplete='email'
                     autoFocus
                     value={values.email}
                     onChange={handleFormChange}
                  />
               </FormControl>
               <FormControl margin='normal' required fullWidth>
                  <InputLabel htmlFor='passwordOne'>Password</InputLabel>
                  <Input
                     id='passwordOne'
                     name='passwordOne'
                     type='password'
                     fullWidth
                     autoComplete='current-password'
                     value={values.passwordOne}
                     placeholder='minimum 6 characters'
                     onChange={handleFormChange}
                  />
               </FormControl>
               <FormControl margin='normal' required fullWidth>
                  <InputLabel htmlFor='passwordTwo'>
                     Confirm New Password
                  </InputLabel>
                  <Input
                     id='passwordTwo'
                     name='passwordTwo'
                     type='password'
                     autoComplete='current-password'
                     placeholder='minimum 6 characters'
                     value={values.passwordTwo}
                     onChange={handleFormChange}
                  />
               </FormControl>
               {error && (
                  <Typography
                     variant='subtitle2'
                     className={classes.errorMessage}
                  >
                     {error}
                  </Typography>
               )}
            </DialogContent>
            <DialogActions>
               <Button onClick={handleClose} size='small'>
                  Cancel
               </Button>
               <Button
                  onClick={onSubmit}
                  disabled={isInvalid}
                  size='small'
                  variant='contained'
                  color='primary'
               >
                  Create
               </Button>
            </DialogActions>
         </form>
      </Dialog>
   );
};

SignUpDialog.propTypes = {
   firebase: PropTypes.object.isRequired
};

const withCompose = compose(withFirebase)(SignUpDialog);

export default withCompose;
