import { createMuiTheme } from '@material-ui/core/styles';
import { purple, green, orange, grey } from '@material-ui/core/colors';
import Constants from './constants';

// A theme with custom primary and secondary color.
export const darkTheme = createMuiTheme({
   palette: {
      type: Constants.THEME_TYPE.DARK,
      primary: {
         light: orange[300],
         main: orange[500],
         dark: orange[700] //         contrastText: '#fff'
      },
      secondary: {
         light: grey[500],
         main: grey[700],
         dark: grey[800],
         contrastText: grey[400]
      }
   },
   overrides: {
      MuiDialog: {
         paper: {
            border: `1px solid ${orange[500]}`,
            borderRadius: '2%'
         }
      }
   }
});

export const lightTheme = createMuiTheme({
   palette: {
      type: Constants.THEME_TYPE.LIGHT,
      // text: {
      //    primary: '#000'
      // },
      primary: {
         light: purple[300],
         main: purple[500],
         dark: purple[700]
      },
      secondary: {
         light: green[300],
         main: green[500],
         dark: green[700]
      }
   }
});
