import { ApolloClient } from 'apollo-boost';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';

const utils = require('../utils');

const getApolloClient = (token = '') => {
   const authLink = setContext((_, { headers }) => {
      return {
         headers: {
            ...headers,
            authorization: token
         }
      };
   });

   const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
         graphQLErrors.map(({ message, locations, path }) =>
            console.log(
               `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
         );
      }

      if (networkError) {
         console.error(`Network error`, networkError);
      }
   });

   const serverProtocol = utils.getServerProtocol();
   const serverPort = utils.getServerPort();

   const httpLink = createHttpLink({
      uri: `${serverProtocol}://${window.location.hostname}:${serverPort}/graphql`
   });
   const link = ApolloLink.from([authLink, errorLink, httpLink]);

   return new ApolloClient({
      link,
      cache: new InMemoryCache()
   });
};

export default getApolloClient;
