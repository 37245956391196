import Constants from '../constants';

export const selectedAlbumMetadataReducer = (state = { path: '/' }, action) => {
   switch (action.type) {
      case 'SET_SELECTED_ALBUM_METADATA':
         return action.selectedAlbumMetadata;
      default:
         return state;
   }
};

export const authDataReducer = (state = null, action) => {
   switch (action.type) {
      case 'SET_AUTH_DATA':
         return action.authData;
      default:
         return state;
   }
};

export const showLoadingSpinnerReducer = (state = null, action) => {
   switch (action.type) {
      case 'SHOW_LOADING_SPINNER':
         return true;
      case 'HIDE_LOADING_SPINNER':
         return false;
      default:
         return state;
   }
};

export const apolloClientReducer = (state = null, action) => {
   switch (action.type) {
      case 'SET_APOLLO_CLIENT':
         return action.apolloClient;
      default:
         return state;
   }
};

export const themeReducer = (state = Constants.THEME_TYPE.DARK, action) => {
   switch (action.type) {
      case 'SET_THEME':
         return action.theme;
      default:
         return state;
   }
};

export const snackbarReducer = (
   state = { message: '', type: Constants.SNACKBAR_MESSAGE_TYPE.DEFAULT },
   action
) => {
   switch (action.type) {
      case 'SET_SNACKBAR':
         return action.payload;
      default:
         return state;
   }
};

const defaultDialogVisibilities = {
   menuVisible: false,
   albumDescriptionVisible: false,
   navigationDrawerVisible: false,
   createAlbumVisible: false,
   addNewFilesVisible: false,
   passwordChangeVisible: false,
   signInWithVisible: false,
   signUpVisible: false,
   passwordResetVisible: false,
   signInWithEmailVisible: false
};

export const dialogVisibilityReducer = (
   state = defaultDialogVisibilities,
   action
) => {
   switch (action.type) {
      case 'SET_OPEN_MENU_VISIBLE':
         return { ...state, menuVisible: action.open };
      case 'SET_ALBUM_DESCRIPTION_VISIBLE':
         return { ...state, albumDescriptionVisible: action.open };
      case 'SET_NAVIGATION_DRAWER_VISIBLE':
         return { ...state, navigationDrawerVisible: action.open };
      case 'SET_CREATE_ALBUM_VISIBLE':
         return { ...state, createAlbumVisible: action.open };
      case 'SET_ADD_NEW_FILES_VISIBLE':
         return { ...state, addNewFilesVisible: action.open };
      case 'SET_PASSWORD_CHANGE_VISIBLE':
         return { ...state, passwordChangeVisible: action.open };
      case 'SET_SIGN_IN_WITH_VISIBLE':
         return { ...state, signInWithVisible: action.open };
      case 'SET_SIGN_UP_VISIBLE':
         return { ...state, signUpVisible: action.open };
      case 'SET_PASSWORD_RESET_VISIBLE':
         return { ...state, passwordResetVisible: action.open };
      case 'SET_SIGN_IN_WITH_EMAIL_VISIBLE':
         return { ...state, signInWithEmailVisible: action.open };
      default:
         return state;
   }
};
