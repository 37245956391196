import Constants from '../constants';

// SET_SELECTED_FOLDER_PATH
export const setSelectedAlbumMetadata = (selectedAlbumMetadata = '') => ({
   type: 'SET_SELECTED_ALBUM_METADATA',
   selectedAlbumMetadata: selectedAlbumMetadata
});

// SET_AUTH_DATA
export const setAuthData = (authData = {}) => ({
   type: 'SET_AUTH_DATA',
   authData
});

export const showLoadingSpinner = () => ({
   type: 'SHOW_LOADING_SPINNER'
});

export const hideLoadingSpinner = () => ({
   type: 'HIDE_LOADING_SPINNER'
});

export const setApolloClient = (apolloClient = null) => ({
   type: 'SET_APOLLO_CLIENT',
   apolloClient
});

export const setTheme = (theme = Constants.THEME_TYPE.DARK) => ({
   type: 'SET_THEME',
   theme
});

export const setSnackbar = (
   payload = { message: '', type: Constants.SNACKBAR_MESSAGE_TYPE.DEFAULT }
) => ({
   type: 'SET_SNACKBAR',
   payload
});

export const setMenuVisible = (open = false) => ({
   type: 'SET_OPEN_MENU_VISIBLE',
   open
});
export const setAlbumDescriptionVisible = (open = false) => ({
   type: 'SET_ALBUM_DESCRIPTION_VISIBLE',
   open
});
export const setNavigationDrawerVisible = (open = false) => ({
   type: 'SET_NAVIGATION_DRAWER_VISIBLE',
   open
});
export const setCreateAlbumVisible = (open = false) => ({
   type: 'SET_CREATE_ALBUM_VISIBLE',
   open
});
export const setAddNewFilesVisible = (open = false) => ({
   type: 'SET_ADD_NEW_FILES_VISIBLE',
   open
});
export const setPasswordChangeVisible = (open = false) => ({
   type: 'SET_PASSWORD_CHANGE_VISIBLE',
   open
});
export const setSignInWithVisible = (open = false) => ({
   type: 'SET_SIGN_IN_WITH_VISIBLE',
   open
});
export const setSignUpVisible = (open = false) => ({
   type: 'SET_SIGN_UP_VISIBLE',
   open
});
export const setPasswordResetVisible = (open = false) => ({
   type: 'SET_PASSWORD_RESET_VISIBLE',
   open
});
export const setSignInWithEmailVisible = (open = false) => ({
   type: 'SET_SIGN_IN_WITH_EMAIL_VISIBLE',
   open
});
