import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import {
   DialogContent,
   DialogActions,
   DialogContentText,
   Typography,
   Dialog,
   Button
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import Progress from '../upload/Progress';
import Dropzone from '../upload/Dropzone';
import { getServerProtocol } from '../../utils';
import { RESET_ALBUM_IMAGE } from '../../graphql/queries';
import Constants from '../../constants';
import { setAddNewFilesVisible } from '../../redux/actions';

const path = require('path');

const styles = theme => ({
   form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1)
   },
   submit: {
      marginTop: theme.spacing(3)
   },
   paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
         3
      )}px`
   },
   content: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: 16,
      boxSizing: 'border-box',
      width: '100%'
   },

   files: {
      alignItems: 'flex-start',
      justifyItems: 'flex-start',
      flex: 1,
      overflowY: 'auto',
      maxHeight: 200
   },

   filename: {
      marginBottom: 8,
      fontSize: 16,
      color: theme.palette.secondary.contrastText
   },

   row: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: 8,
      overflow: 'hidden',
      boxSizing: 'border-box'
   },

   checkIcon: {
      opacity: 0.5,
      marginLeft: 32
   },

   progressWrapper: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center'
   }
});

const ImageUploadDialog = ({
   classes,
   location: { pathname, search },
   history
}) => {
   // const [error, setError] = useState('');
   const [files, setFiles] = useState([]);
   const [uploading, setUploading] = useState(false);
   const [uploadProgress, setUploadProgress] = useState({});
   const [successfullUploaded, setSuccessfullUploaded] = useState(false);

   const apolloClient = useSelector(state => state.apolloClient);
   const addNewFilesVisible = useSelector(
      state => state.dialogVisibility.addNewFilesVisible
   );
   const { token } = useSelector(state => state.authData || {});
   const dispatch = useDispatch();

   const onFilesAdded = addedFiles => {
      const mediaFiles = addedFiles.filter(file =>
         Constants.MEDIA_TYPES.includes(path.extname(file.name).toLowerCase())
      );

      setFiles(prevState => [...prevState, ...mediaFiles]);
   };

   const uploadFiles = async () => {
      setUploadProgress({});
      setUploading(true);

      const promises = [];

      files.forEach(file => {
         promises.push(sendRequest(file));
      });
      try {
         await Promise.all(promises);

         setSuccessfullUploaded(true);
         setUploading(false);

         // update MongoDB metadata
         await resetAlbumImage(pathname);
         // re-render page
         history.push(`${pathname}${search}`);
      } catch (e) {
         console.log('@@@');
         console.log(e);
         setSuccessfullUploaded(true);
         setUploading(false);

         console.log(JSON.stringify(e));
      }
   };

   const resetAlbumImage = async albumPath => {
      await apolloClient.mutate({
         mutation: RESET_ALBUM_IMAGE,
         variables: {
            path: albumPath
         }
      });
   };

   const sendRequest = file => {
      return new Promise((resolve, reject) => {
         const req = new XMLHttpRequest();

         req.upload.addEventListener('progress', event => {
            if (event.lengthComputable) {
               setUploadProgress(prevState => ({
                  ...prevState,
                  [file.name]: {
                     state: 'pending',
                     percentage: (event.loaded / event.total) * 100
                  }
               }));
            }
         });

         req.upload.addEventListener('load', () => {
            setUploadProgress(prevState => ({
               ...prevState,
               [file.name]: {
                  state: 'done',
                  percentage: 100
               }
            }));

            resolve(req.response);
         });

         req.upload.addEventListener('error', () => {
            setUploadProgress(prevState => ({
               ...prevState,
               [file.name]: {
                  state: 'error',
                  percentage: 0
               }
            }));

            console.log('ERROR');
            reject(req.response);
         });

         const formData = new FormData();
         formData.append('file', file, file.name);

         req.open(
            'POST',
            `${getServerProtocol()}://${
               window.location.hostname
            }/api/upload?path=${pathname}`
         );
         req.setRequestHeader('Authorization', token);
         req.send(formData);
      });
   };

   const renderProgress = file => {
      const progress = uploadProgress[file.name];

      if (uploading || successfullUploaded) {
         return (
            <div className={classes.progressWrapper}>
               <Progress progress={progress ? progress.percentage : 0} />
               <CheckCircleIcon
                  className={classes.checkIcon}
                  style={{
                     opacity: progress && progress.state === 'done' ? 0.5 : 0
                  }}
               />
            </div>
         );
      }
      return null;
   };

   const handleClose = () => {
      dispatch(setAddNewFilesVisible(false));
   };

   return (
      <Dialog
         fullWidth
         maxWidth='xs'
         open={addNewFilesVisible}
         onEnter={() => {
            setFiles([]);
            setUploading(false);
            setUploadProgress({});
            // setError('');
            setSuccessfullUploaded(false);
         }}
      >
         <DialogContent className={classes.paper}>
            <DialogContentText color='primary'>Upload files</DialogContentText>
            <form>
               <div className={classes.content}>
                  <Dropzone onFilesAdded={onFilesAdded} disabled={uploading} />
                  <div className={classes.files}>
                     {files.map(file => {
                        return (
                           <div key={file.name} className={classes.row}>
                              <span className={classes.filename}>
                                 {file.name}
                              </span>
                              {renderProgress(file)}
                           </div>
                        );
                     })}
                  </div>
               </div>
            </form>
         </DialogContent>
         <DialogActions>
            {uploading ? (
               <>
                  <Typography>Upload in progress..</Typography>
               </>
            ) : (
               <>
                  <Button
                     onClick={handleClose}
                     size='small'
                     disabled={uploading}
                  >
                     Close
                  </Button>
                  <Button
                     onClick={() => {
                        setFiles([]);
                     }}
                     size='small'
                     disabled={files.length === 0}
                  >
                     Clear
                  </Button>
                  <Button
                     color='primary'
                     variant='contained'
                     size='small'
                     disabled={files.length === 0 || uploading}
                     onClick={uploadFiles}
                  >
                     Upload
                  </Button>
               </>
            )}
         </DialogActions>
      </Dialog>
   );
};
ImageUploadDialog.propTypes = {
   classes: PropTypes.object.isRequired,
   location: PropTypes.object.isRequired,
   history: PropTypes.object.isRequired
};

const withCompose = compose(
   withStyles(styles),
   withRouter
)(ImageUploadDialog);

export default withCompose;
