import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Snackbar, IconButton } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { compose } from 'recompose';
import { withTheme } from '@material-ui/styles';
import { useSelector, useDispatch } from 'react-redux';
import Constants from '../../constants';
import { setSnackbar } from '../../redux/actions';

const styles = theme => {
   return {
      default: {
         //   backgroundColor: theme.palette.background.default,
         //    color: theme.palette.secondary.contrastText
      },
      error: {
         backgroundColor: theme.palette.error.main,
         color: theme.palette.error.contrastText
      },
      info: {
         backgroundColor: theme.palette.primary.dark
      }
   };
};

const CustomSnackbar = ({ classes }) => {
   const message = useSelector(state => state.snackbar.message);
   const type = useSelector(state => state.snackbar.type);
   const dispatch = useDispatch();

   return (
      <Snackbar
         anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
         }}
         open={!!message}
         autoHideDuration={5000}
         onClose={() => {
            dispatch(setSnackbar());
         }}
         ContentProps={{
            'aria-describedby': 'message-id',
            classes: {
               root: clsx({
                  [classes.error]:
                     type === Constants.SNACKBAR_MESSAGE_TYPE.ERROR,
                  [classes.default]:
                     !type || type === Constants.SNACKBAR_MESSAGE_TYPE.INFO
               })
            }
         }}
         message={<span id='message-id'>{message}</span>}
         action={[
            <IconButton
               key='close'
               aria-label='Close'
               color='inherit'
               onClick={() => {
                  dispatch(setSnackbar());
               }}
            >
               <CloseIcon />
            </IconButton>
         ]}
      />
   );
};

CustomSnackbar.propTypes = {
   classes: PropTypes.object.isRequired
};

const withCompose = compose(
   withStyles(styles),
   withTheme
)(CustomSnackbar);

export default withCompose;
